import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useTheme } from '../Flowbite/ThemeContext';
import AvatarGroup from './AvatarGroup';
import AvatarGroupCounter from './AvatarGroupCounter';
const AvatarComponent = ({ alt = '', bordered = false, children, img, color = 'light', rounded = false, size = 'md', stacked = false, status, statusPosition = 'top-left', placeholderInitials = '', className, ...props }) => {
    const theme = useTheme().theme.avatar;
    const imgClassName = classNames(bordered && theme.bordered, bordered && theme.color[color], rounded && theme.rounded, stacked && theme.stacked, theme.img.on, theme.size[size]);
    return (_jsxs("div", { className: classNames(theme.base, className), "data-testid": "flowbite-avatar", ...props, children: [_jsxs("div", { className: "relative", children: [img ? (_jsx("img", { alt: alt, className: classNames(imgClassName, theme.img.on), "data-testid": "flowbite-avatar-img", src: img })) : placeholderInitials ? (_jsx("div", { className: classNames(theme.img.off, theme.initials.base, rounded && theme.rounded, stacked && theme.stacked, bordered && theme.bordered), children: _jsx("span", { className: classNames(theme.initials.text), "data-testid": "flowbite-avatar-initials-placeholder", children: placeholderInitials }) })) : (_jsx("div", { className: classNames(imgClassName, theme.img.off), "data-testid": "flowbite-avatar-img", children: _jsx("svg", { className: theme.img.placeholder, fill: "currentColor", viewBox: "0 0 20 20", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { fillRule: "evenodd", d: "M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z", clipRule: "evenodd" }) }) })), status && (_jsx("span", { className: classNames(theme.status.base, theme.status[status], theme.statusPosition[statusPosition]) }))] }), children && _jsx("div", { children: children })] }));
};
AvatarComponent.displayName = 'Avatar';
export const Avatar = Object.assign(AvatarComponent, {
    Group: AvatarGroup,
    Counter: AvatarGroupCounter,
});
