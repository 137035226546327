import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { GoThreeBars } from 'react-icons/go';
import { useTheme } from '../Flowbite/ThemeContext';
import { useNavbarContext } from './NavbarContext';
export const NavbarToggle = ({ barIcon: BarIcon = GoThreeBars, className, ...props }) => {
    const { isOpen, setIsOpen } = useNavbarContext();
    const handleClick = () => {
        setIsOpen(!isOpen);
    };
    const theme = useTheme().theme.navbar.toggle;
    return (_jsxs("button", { className: classNames(theme.base, className), "data-testid": "flowbite-navbar-toggle", onClick: handleClick, ...props, children: [_jsx("span", { className: "sr-only", children: "Open main menu" }), _jsx(BarIcon, { className: theme.icon })] }));
};
