import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { useTheme } from '../Flowbite/ThemeContext';
export const NavbarLink = ({ active, disabled, href, children, className, ...props }) => {
    const theme = useTheme().theme.navbar.link;
    return (_jsx("li", { children: _jsx("a", { href: href, className: classNames(theme.base, {
                [theme.active.on]: active,
                [theme.active.off]: !active && !disabled,
            }, theme.disabled[disabled ? 'on' : 'off'], className), ...props, children: children }) }));
};
